import React, { useState } from 'react'
import{ Anchor, Tab, Tabs, TabBody, Fieldset, NumberField, Checkbox, Cutout, Radio } from 'react95'
import DraggableWindow from './DraggableWindow'
import RadioTabBody from './RadioTabBody'
import { MicroscopeNES, MicroscopeVGA, PCBAssm, PCBAssmHQ, OldComputer, OldComputerHQ, ETASImg, ETASImgHQ } from '../assets'

function WhatWeDo(props) {
  const [activeTab, setActiveTab] = useState(0)
  const { tabs, style } = props
  const [checkedValue, setCheckedValue] = useState('hardware')
  const [pcbimg, setPcbimg] = useState(PCBAssm)
  const listPad = '5px'

  function handleChange(e) {
    setCheckedValue(e.target.value)
  }

  const pcbsrc = `url(${pcbimg})`

  const engineering_contents = [
    {
      label: '📟 Hardware',
      value: 'hardware',
      contents: 
        <div>
          Give us anything from a paper napkin sketch to a full specification and we'll develop your idea into a complete schematic, bill of materials, and PCB layout, and assembled prototype. <br></br><br></br>
          We're familiar with a wide range of microcontrollers, sensors, actuators, and communications systems and protocols that can bring bring your idea to life.
        </div>,
      img: PCBAssm,
      imgHQ: PCBAssmHQ
    },
    {
      label: '💻 Firmware',
      value: 'firmware',
      contents:        
        <div>
          TinkerTech specializes in developing bare metal C/C++ firmware development for PIC and AVR microcontrollers, and Python for embedded Linux. With years of experience developing for realtime and Internet of Things applications, we can make sure your device is fast, responsive, optimized for battery life, and ready for FCC testing.          
        </div>,
      img: OldComputer,
      imgHQ: OldComputerHQ
    },
    {
      label: '🤖 Mechanical',
      value: 'mechanical',
      contents: 
        <div>
          Need an enclosure for that widget of yours? We can help with that too. We can generate 3D solid models, detailed engineering drawings, and 3D printed rapid prototypes. We have experience in designing for injection molding, as well, so we'll be able to help you be ready to bring your product to scale.,
        </div>,      
      img: ETASImg,
      imgHQ: ETASImgHQ
    },
    {
      label: '🌐 Web',
      value: 'web',
      contents: 
        <div>
          Does your widget need to talk to the internet? We can help with that too. Whether you need a 
        </div>,      
      img: ETASImg,
      imgHQ: ETASImgHQ
    }
  ]

  return (
    <DraggableWindow title='What We Do' toolbar={null} contextName='what_we_do' style={{width: '900px', height: '525px'}}>
      <>
        <Tabs value={activeTab} onChange={value => setActiveTab(value)}>
          <Tab value={0}>Product Development</Tab>
          <Tab value={1}>Engineering</Tab>          
          <Tab value={2}>Tools We Use</Tab>
        </Tabs>
        <div style={{height: '400px'}}>
          {activeTab === 0 &&
          <TabBody>
            <div style={{display: 'flex'}}>
              <div style={{padding: '0px 15px 0px 15px'}}>
                We specialize in product design and implementation for embedded
                systems. If you have a device or an idea for a device that automates,
                blinks, moves, talks to other devices, logs data, or any other myriad
                of applications, we can help you turn that idea into a prototype or
                refine an existing prototype into a commercializable product.

                Examples of past projects we've worked on include:

                <ul style={{paddingLeft: '20px', paddingTop: '20px'}}>
                  <li style={{paddingBottom: listPad}}>
                    &#8227;&nbsp;
                    <Anchor href="https://www.dynamicperception.com/Digital-NMX-timelapse-and-video-motion-controller-p/dp-nmx-pro.htm" target="_blank">
                      3-Axis Bluetooth connected stepper motor controller
                    </Anchor>
                  </li>
                  <li style={{paddingBottom: listPad}}>&#8227;&nbsp;CAN bus networked magnetic field sensors for three-dimensional position sensing</li>
                  <li style={{paddingBottom: listPad}}>&#8227;&nbsp;Biometrically secured controlled substance dispenser</li>
                  <li style={{paddingBottom: listPad}}>&#8227;&nbsp;Optical ground-to-air communications system </li>
                  <li style={{paddingBottom: listPad}}>
                    &#8227;&nbsp;
                    <Anchor href="http://tinkertech.io/downloads/tinkerbot_V2.0.0_instructions.pdf" target="_blank">
                      Educational Robot Kits
                    </Anchor>
                  </li>
                </ul>
              </div>
              <div>
                <Cutout style={{ width: '400px', height: '300px', backgroundSize: 'cover', backgroundImage: `url(${MicroscopeNES})` }}>
                </Cutout>
              </div>
            </div>
          </TabBody>
          }

          {activeTab === 1 && (
            <RadioTabBody options={engineering_contents}/>
          )}         
          {activeTab === 2 &&
            <TabBody>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>

                <div style={{width: '300px', margin: '10px'}}>
                    If you're looking for the nitty-gritty of how we get the job done, here are the details of the most common go-to items in our toolbox.
                    We're in no way limited to the technologies listed here, but we're are especially familiar with them.
                </div>

                <div>
                  <div className='layoutHoriz'>
                    <div className='listOuterContainerL' style={{margin: '10px'}}>
                      <div className='listInnerContainer'>
                        <h3 className='h3custom'>Desktop</h3>
                        <div style={{marginTop: '10px'}}>
                          <p>• Python</p>
                          <p>• Qt</p>
                          <p>• Electron</p>
                        </div>
                      </div>
                    </div>
                    <div className='listOuterContainer' style={{margin: '10px'}}>
                      <div className='listInnerContainer'>
                        <h3 className='h3custom'>Embedded</h3>
                        <div style={{marginTop: '10px'}}>
                          <p>• C/C++</p>
                          <p>• Linux</p>
                          <p>• Python</p>
                        </div>
                      </div>
                    </div>
                    <div className='listOuterContainerL' style={{margin: '10px'}}>
                      <div className='listInnerContainer'>
                        <h3 className='h3custom'>Web</h3>
                        <div style={{marginTop: '10px'}}>
                          <p>• Javascript</p>
                          <p>• PHP</p>
                          <p>• React</p>                      
                          <p>• AWS EC2</p>
                          <p>• Firebase</p>
                          <p>• Firestore</p>
                          <p>• PostgreSQL</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='layoutHoriz'>
                    <div className='listOuterContainer' style={{margin: '10px'}}>
                      <div className='listInnerContainer'>
                        <h3 className='h3custom'>Communications</h3>
                        <div style={{marginTop: '10px'}}>
                          <p>• UART</p>
                          <p>• SPI</p>
                          <p>• I2C</p>
                          <p>• CANBus</p>
                          <p>• BLE</p>
                          <p>• LoRa</p>
                          <p>• ChirpStack</p>
                          <p>• LTE (IoT Cellular)</p>
                        </div>
                      </div>
                    </div>
                    <div className='listOuterContainer' style={{margin: '10px'}}>
                      <div className='listInnerContainer'>
                        <h3 className='h3custom'>Design</h3>
                        <div style={{marginTop: '10px'}}>
                          <p>• SolidWorks</p>
                          <p>• KiCad</p>
                        </div>
                      </div>
                    </div>
                    <div className='listOuterContainer' style={{margin: '10px'}}>
                      <div className='listInnerContainer'>
                        <h3 className='h3custom'>Fabrication</h3>
                        <div style={{marginTop: '10px'}}>
                          <p>• Hand Soldering</p>
                          <p>• Pick & Place + Reflow Soldering</p>
                          <p>• 3D Printing</p>
                          <p>• Laser Cutting</p>
                          <p>• CNC Milling</p>
                          <p>• Injection Molding</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabBody>
          }
        </div>
      </>
    </DraggableWindow>
  )
}

export default WhatWeDo
